import { useState } from 'react';
import InputMask from 'react-input-mask';

import './forms-contact.css';
import emailjs from '@emailjs/browser';
import BtnWpp from './btn-wpp';




function FormsContact() {
    const [celphone, setCelphone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');
    

    function sendPhone(e){
        e.preventDefault();

        if(celphone === '' || name === ''){
            alert("Nome e telefone são campos obrigatóros. Preencha para continuar.");
            
            return; 
        }

       
       
        const templateParams = {
            name: name,
            celphone: celphone,
            email: email,
            city: city,
            message: message
        }

        emailjs.send("service_t5jql8o", "template_dfmgs84", templateParams, "_aq30s6kFTWjKWa1l")
        .then((response) => {
            console.log('email enviado', response.status, response.text)
            setName('');
            setCelphone('');
            setEmail('');
            setCity('');
            setMessage('');
            alert("Enviado com sucesso! Logo, logo, já vamos lhe chamar!")
            //window.location.href = "https://mprojeto3d.com/Muitoobrigado"
        }, (err) =>{
            console.log('errooo: ', err)
        })
    }


  
    
    return(
         
        <>
            <form onSubmit={sendPhone}>
                <fieldset>
                    <div className="input-vertical-block">
                        <InputMask 
                            placeholder="Digite seu nome" 
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name} 
                        />   
                        <InputMask 
                            placeholder="Digite seu melhor email" 
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email} 
                        />
                        <InputMask 
                            placeholder="Digite sua cidade" 
                            type="tel"
                            onChange={(e) => setCity(e.target.value)}
                            value={city} 
                        /> 
                          <InputMask 
                            mask="(99)9.9999.9999"
                            placeholder="Digite seu celular com DDD" 
                            type="tel"
                            onChange={(e) => setCelphone(e.target.value)}
                            value={celphone} 
                        /> 
                        <input 
                            placeholder="Como podemos ajudar você?" 
                            type="tel"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message} 
                        />   
                        <button type="submit" className='btn-submit'>Enviar</button> 
                    </div>
                </fieldset>

                <a 
                href='https://api.whatsapp.com/send/?phone=5551999078668&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                alt="" target='_blank' rel="noreferrer"
                >
                    <BtnWpp />
                </a>
            </form>
        </>
    );
}

export default FormsContact;