import './btn-default1.css';


function BtnDefault3() {
    return(
        <>
            <button type="button" className='btn-default'>QUERO UM ORÇAMENTO</button>
        </>
    );
}

export default BtnDefault3;