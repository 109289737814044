import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import Img1 from '../../assets/img1-supredemaxcenter.jpg';
import Img2 from '../../assets/img2-supredemaxcenter.jpg';
import Img3 from '../../assets/img3-supredemaxcenter.jpg';
import Img4 from '../../assets/img4-supredemaxcenter.jpg';
import Img5 from '../../assets/img5-supredemaxcenter.jpg';
import Img6 from '../../assets/img6-supredemaxcenter.jpg';


import './galllery.css';


import {MdNavigateNext, MdClose} from 'react-icons/md';
import {RiArrowLeftSLine} from 'react-icons/ri';
import { IconContext } from 'react-icons';
 
const images = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6
]


const GallerySuperredemaxcenter = () => {

    const [data, setData] = useState({img: '', i: 0})  

    const viewImage = (img, i)=>{
           setData({img, i})
    }

    const imgAction = (action) => {
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }

    return(
       
        <>
            {data.img &&
                <>
                    <div className='div-out'>
                        <img src={data.img} className='img-out' alt=''/>
                        <IconContext.Provider value={{ className: 'itprevious', color: 'white'}}>
                            <RiArrowLeftSLine 
                                onClick={() => imgAction('previous-img')}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'it' }}>
                            <MdClose onClick={() => imgAction()}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'itnext' }}>
                                <MdNavigateNext        
                                onClick={() => imgAction('next-img')}/>
                        </IconContext.Provider>
                    </div>
                </>
            }
            <div className='galllery-global'>
                 
                <ResponsiveMasonry 
                    columnsCountBreakPoints={{350: 1, 750: 2, 900:3}}>
                        <Masonry gutter='20px'>
                            {
                                images.map((image, i) => (
                                    <img 
                                        key={i}
                                        src={image}
                                        className='img-responsive'
                                        alt=""
                                        onClick={()=> viewImage(image, i)}
                                    />
                                ))
                            }
                        </Masonry>
                </ResponsiveMasonry>
                
            </div>
        </>
    );
}

export default GallerySuperredemaxcenter;



