import './wrapperclients.css';

import movexlogopreto1 from '../assets/movexlogopreto.png';
import movexlogopreto2 from '../assets/movexlogopreto.png';
import movexlogopreto3 from '../assets/movexlogopreto.png';
import movexlogopreto4 from '../assets/movexlogopreto.png';
import movexlogopreto5 from '../assets/movexlogopreto.png';

function WrapperClients() {
    return(
        <>
        <div className="wrapper">
            <img src={movexlogopreto1} alt="teste" className='img-client1'/>

            <img src={movexlogopreto2} alt="teste" className='img-client2'/>

            <img src={movexlogopreto3} alt="teste" className='img-client3'/>

            <img src={movexlogopreto4} alt="teste" className='img-client4'/>

            <img src={movexlogopreto5} alt="teste" className='img-client5'/>  
        </div>
        </>
    );
}

export default WrapperClients;



