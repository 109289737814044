import React from 'react';

import Rotas from './routes';

function App() {
  return (
    <Rotas />
  );
}

export default App;
