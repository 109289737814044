import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import Img1 from '../assets/img1.jpg';
import Img2 from '../assets/img2.jpg';
import Img3 from '../assets/img3.jpg';
import Img4 from '../assets/img4.jpg';
import Img5 from '../assets/img5.jpg';
import Img6 from '../assets/img6.jpg';
import Img7 from '../assets/img7-sup-maxcenter.jpg';


/*import ImgVertical01 from '../assets/img1.webp';
import ImgVertical02 from '../assets/img2.webp';*/


import './gallery.css';


import {MdNavigateNext, MdClose} from 'react-icons/md';
import {RiArrowLeftSLine} from 'react-icons/ri';
import { IconContext } from 'react-icons';
 
const images = [
     { src: Img3, text: "VEJA MAIS - POSTO FRIDERICHS", link: "/postofriederichs" },
     { src: Img7, text: "VEJA MAIS - SUPER REDE MAX CENTER", link: "/superredemaxcenter" },

  /*Img3,
  Img7,
  Img3,
  Img5,
  Img6,
  Img2*/
]


const Gallery = () => {

    const [data, setData] = useState({img: '', i: 0})  

    const viewImage = (img, i)=>{
           setData({img, i})
    }

    const imgAction = (action) => {
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }

    return(
       
        <>
            {data.img &&
                <>
                    <div className='div-out'>
                        <img src={data.img} className='img-out' alt=''/>
                        <IconContext.Provider value={{ className: 'itprevious', color: 'white'}}>
                            <RiArrowLeftSLine 
                                onClick={() => imgAction('previous-img')}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'it' }}>
                            <MdClose onClick={() => imgAction()}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'itnext' }}>
                                <MdNavigateNext        
                                onClick={() => imgAction('next-img')}/>
                        </IconContext.Provider>
                    </div>
                </>
            }
            <div className='gallery-global'>
                <div style={{
                    display: 'grid',
                    placeItems: 'center', // Centraliza vertical e horizontalmente
                    width: '100%',
                    height: '100%', // Ajuste conforme necessário
                    }}>
                    
                        <ResponsiveMasonry 
                        columnsCountBreakPoints={{350: 1, 750: 2, 900:3}}
                        style={{
                            width: '80%', // Defina a largura máxima
                            maxWidth: '1200px', // Opcional: limite o tamanho
                            height: '100%'
                           
                          }}>
                            <Masonry gutter='160px' className='masonry-column'>
                                {
                                    images.map((image, i) => (
                                       
                                        <div key={i} className="img-container">
      <Link to={image.link} className="image-link">

      <img 
          src={image.src} 
          className="immg-responsive" 
          alt={image.text} 
        />
                                        <div className="overlay">
                                            <span className="overlay-text">{image.text}</span>
                                        </div>
                                        </Link>

                                        </div>
                                    ))
                                }
                            </Masonry>
                    </ResponsiveMasonry>
                </div>
                { /* 
                <ResponsiveMasonry 
                    columnsCountBreakPoints={{350: 1, 750: 2, 900:3}}>
                        <Masonry gutter='20px'>
                            {
                                images.map((image, i) => (
                                    <img 
                                        key={i}
                                        src={image}
                                        className='img-responsive'
                                        alt=""
                                        onClick={()=> viewImage(image, i)}
                                    />
                                ))
                            }
                        </Masonry>
                </ResponsiveMasonry>
                */}
            </div>
        </>
    );
}

export default Gallery;



