import './btn-default1.css';


function BtnDefault1() {
    return(
        <>
            <button type="button" className='btn-default'>TAMBÉM QUERO SER CLIENTE</button>
        </>
    );
}

export default BtnDefault1;