import './btn-wpp.css';
import { ImWhatsapp } from "react-icons/im";


function BtnWpp() {


    return(
        <>
            <button 
                type="button" 
                className='btn-wpp'
                >
                <ImWhatsapp className='iconwpp'/>FALE CONOSCO
            </button>  
        </>
    );
}

export default BtnWpp;