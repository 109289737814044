import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';



import LandingPage from './pages/LandingPage';
import Portfolio from './pages/Portfolio';
import Initial from './pages/Initial';
import Company from './pages/Company';
import Clientes from './pages/Clients';
import Contact from './pages/Contact';
import Postofriederichs from './components/portfolio/postofriederichs';
import Superredemaxcenter from './components/portfolio/superredemaxcenter';



function Rotas(){
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Initial />} />
              
                <Route path="/Initial" element={<Initial />} />
                <Route path="/Portfolio" element={<Portfolio />} />
                <Route path="/company" element={<Company />} />
                <Route path="/clients" element={<Clientes />} />
                <Route path="/Contact" element={<Contact />} />

                <Route path="/postofriederichs" element={<Postofriederichs />} />
                <Route path="/superredemaxcenter" element={<Superredemaxcenter/>} />

            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;