import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

export const NavMenuLink = styled(Link)`
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    
    &:hover {
        transition: all .2s ease-in-out;
        color: white;
    }
    &.active {
        color: #15cdfc;
    }
`;


export const Nav = styled.nav`
    background: #F58320;
    height: 90px;
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    z-index: 1999;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 2px solid #000;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.5);
`;

export const NavLink = styled(Link)`
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    padding: 0 1rem;
    
    &:hover {
        transition: all .2s ease-in-out;
        color: white;
    }
    &.active {
        color: #fff;
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;

        &.active {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: absolute;
            top: 90px;
            left: 0;
            background: #F58320;
        }
    }
`;
