import './btn-default1.css';


function BtnDefault2() {
    return(
        <>
            <button type="button" className='btn-default'>QUERO UMA CONSULTORIA</button>
        </>
    );
}

export default BtnDefault2;