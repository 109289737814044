import React, { useState } from "react";
import { Nav, NavLink, Bars, NavMenu } from './NavbarElement';
import movexlogopreto2 from '../../assets/movexlogopreto.png';
import './index.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Nav>
                <NavLink to="/Initial">
                    <img src={movexlogopreto2} alt="teste" className='logobar'/>
                </NavLink>
                
                <Bars onClick={toggleMenu} />

                <NavMenu className={isOpen ? 'active' : ''}>
                    <NavLink to="/Initial" activestyle>
                        Home
                    </NavLink>
                    <NavLink to="/portfolio " activestyle>
                        Portfolio
                    </NavLink>
                    <NavLink to="/company" activestyle>
                        Empresa
                    </NavLink>
                   {/* <NavLink to="/clients" activestyle>
                        Clientes
                    </NavLink>*/}
                    <NavLink to="/contact" activestyle>
                        Contato
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;
